import React from 'react'

function HomeIntro() {
    return (
        <div className="container py-20 md:py-32 text-navy">


            <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
                <div className="text-center col-span-full">
                    <div className="font-black tracking-wider uppercase font-display text-light-navy">what we do</div>
                    <div className="pb-8 text-3xl font-semibold font-display">Design as Business Intelligence & Risk Mitigation</div>
                    <div className="pb-10 text-xl">We partner with organisations in the Finacial Services, Public Services, Healthcare, Retail, Consumer Goods and Non-Governmental sectors across Africa to reimagine and build viable, sustainable, feasible and desirable solutions to business and user problems.</div>
                </div>


                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div className="bg-green-400">
                        <img className="overflow-hidden mix-blend-multiply filter grayscale" src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,w_500/v1627071467/minimumviable/images/insights-and-analytics.jpg" alt="Insights and Analytics" />
                    </div>
                    <div className="px-4 py-12 text-lg font-semibold text-gray-700 md:text-center">
                        Insights & Analytics
                        <div>
                            <p className="mt-4 text-base font-normal">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ullam?</p>
                        </div>
                    </div>
                </div>

                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div className="bg-green-400">
                        <img className="overflow-hidden mix-blend-multiply filter grayscale" src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,w_500/v1627068714/minimumviable/images/experience-and-innovation.jpg" alt="Experience and Innovation" />
                    </div>
                    <div className="px-4 py-12 text-lg font-semibold text-gray-700 md:text-center">
                        Experience & Innovation
                        <div>
                            <p className="mt-4 text-base font-normal">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ullam?</p>
                        </div>
                    </div>
                </div>

                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div className="bg-green-400">
                        <img className="overflow-hidden mix-blend-multiply filter grayscale" src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,w_500/v1627068232/minimumviable/images/organisation-and-culture.jpg" alt="Organisation and Culture" />
                    </div>
                    <div className="px-4 py-12 text-lg font-semibold text-gray-700 md:text-center">
                        Organisation & Culture
                        <div>
                            <p className="mt-4 text-base font-normal">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ullam?</p>
                        </div>
                    </div>
                </div>

                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div className="bg-green-400">
                        <img className="overflow-hidden mix-blend-multiply filter grayscale" src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,w_500/v1627070198/minimumviable/images/delivery-and-enablement.jpg" alt="Delivery and Enablement" />
                    </div>
                    <div className="px-4 py-12 text-lg font-semibold text-gray-700 md:text-center">
                        Delivery & Enablement
                        <div>
                            <p className="mt-4 text-base font-normal">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ullam?</p>
                        </div>
                    </div>
                </div>









            </div>



        </div>
    )
}

export default HomeIntro
