import React from 'react'
import { Link } from 'gatsby';

const getCurrentYear = () => {
    return new Date().getFullYear();
};

const navigation = {
    main: [
        { name: 'Expertise', href: '/expertise' },
        { name: 'Solutions', href: '/solutions' },
        { name: 'Work', href: '/work' },
        { name: 'Perspectives', href: '/perspectives' },
        { name: 'About', href: '/about' },
        { name: 'Contact', href: '/contact' },
    ],
}

export default function Footer() {
    return (
        <footer className="font-semibold text-white border-t-2 border-gray-400 bg-navy">
            <div className="px-4 py-12 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8">
                <nav className="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link to={item.href} className="text-base text-white-50 hover:text-green">
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </nav>

                <p className="mt-8 text-base text-center text-gray-400">&copy; 2008 - {getCurrentYear()} | Minimum Viable (Pty) Ltd. All rights reserved.</p>
            </div>
        </footer>
    )
}
