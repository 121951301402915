import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import Billboard from "../components/Billboard"
import HomeIntro from "../components/HomeIntro"
import ClientLogos from "../components/ClientLogos"
import CasePreview from "../components/CasePreview"
import PerspectivesPreview from "../components/PerspectivesPreview"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Minimum Viable | Pan-African Design & Innovation Studio</title>
        <meta name="description" content="Minimum Viable | Pan African Experience Design Consultancy" />
        <meta name="keywords" content="minimum viable, pan african, experience design, design thinking, ux, design sprints, innovation, johannesburg, cape town, nairobi, web development, mvp, design" />
      </Helmet>
      <Billboard />
      <div className=""></div>
      {/* <div className="p-8 max-w-7xl">
        <button className="px-4 py-4 text-white rounded bg-minimum-green hover:bg-minimum-green-600">Button One</button></div> */}
      <section id="home-intro" className="bg-white">
        <HomeIntro />
      </section>

      <section id="cases" className="bg-white-50">
        <CasePreview />
      </section>

      <section id="perspectives" className="bg-white">
        <PerspectivesPreview />
      </section>

      <section id="client-logos" className="bg-navy"> <ClientLogos />
      </section>

    </Layout>
  )
}
