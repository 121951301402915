import React from 'react'


function ClientLogos() {
    return (
        <div className="container py-20 text-white">
            <div className="">
                <div className="">
                    <div className="items-center justify-between md:flex">
                        <div>

                            <h2 className="text-3xl font-semibold sm:text-4xl">
                                You're in great company
                            </h2>
                            <p className="max-w-3xl mt-3 text-xl">
                                We are proud to have partnered with these leading organisations. Want to add yours to the list?
                            </p>
                        </div>
                        <div className="hidden mt-8 ml-auto md:block md:mt-0">

                            <button className="px-4 py-2 font-semibold uppercase border-2 rounded text-green border-green hover:bg-green hover:text-navy">request a call</button>
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-5 lg:mt-12 items-center">

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299152/minimumviable/client-logos/sbsa.svg" alt="Standard Bank South Africa" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627307345/minimumviable/client-logos/mojamoney.svg" alt="Moja Money" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299150/minimumviable/client-logos/oldmutual.svg" alt="Old Mutual" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299152/minimumviable/client-logos/absa.svg" alt="ABSA" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto max-h-10" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627467584/minimumviable/client-logos/franc.app.svg" alt="Franc App" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627307344/minimumviable/client-logos/mercedes-benz.svg" alt="Mercedez Benz" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627307344/minimumviable/client-logos/michael-mikiala.svg" alt="Michael Mikiala Men" />
                        </div>

                        <div className="flex justify-center col-span-1 px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299151/minimumviable/client-logos/ggda.svg" alt="GGDA" />
                        </div>


                        <div className="px-8 py-8">
                            <img className="mx-auto" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299150/minimumviable/client-logos/conhill.svg" alt="Constitution Hill" />
                        </div>


                        <div className="px-8 py-8">
                            <img className="mx-auto max-h-6" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299151/minimumviable/client-logos/lularent.svg" alt="Lula Rent" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto max-h-20" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627307345/minimumviable/client-logos/ukzn.svg" alt="UKZN" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto max-h-20" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627299150/minimumviable/client-logos/civicus.svg" alt="Civicus" />
                        </div>

                        <div className="px-8 py-8">
                            <img className="mx-auto max-h-16" src="https://res.cloudinary.com/minimum-viable/image/upload/v1627307344/minimumviable/client-logos/eThekwini.svg" alt="eThekwini" />
                        </div>

                    </div>
                    <div className="mx-auto mt-8 md:hidden">

                        <button className="px-4 py-2 font-semibold uppercase border-2 rounded text-green border-green hover:bg-green hover:text-navy">request a call</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientLogos
