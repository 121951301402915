import React from 'react'
import { Link } from 'gatsby'

function CasePreview() {
    return (
        <div className="container py-20 md:py-32 text-navy">
            <div className="">
                <div className="font-black tracking-wider uppercase font-display text-light-navy">work</div>
                <div className="pb-20 text-3xl font-semibold font-display">Select Case Studies</div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                <div className="bg-cover md:col-span-2 bg-navy">

                    <div className="px-6 py-10" >
                        <div className="">
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Service Design</span>
                            </Link>
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Experience Design</span>
                            </Link>
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Innovation</span>
                            </Link>
                        </div>
                        <div className="mt-8">
                            <h3 className="mb-4 text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, tempora!</h3>
                            <p className="mb-8 text-lg text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quis sint explicabo voluptate enim dolorum maiores autem numquam sequi accusantium quaerat omnis, corporis ratione in.</p>
                            <div >
                                <button><span className="pb-1 font-semibold text-white border-b-4 border-white hover:text-green hover:border-green">read more</span> </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="bg-navy">
                    <div className="px-6 py-10 bg-cover" >
                        <div className="">
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">UX Design</span>
                            </Link>
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Experience Engineering</span>
                            </Link>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, tempora!</h3>
                            <p className="mt-4 text-lg text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quis sint explicabo voluptate enim dolorum maiores autem numquam sequi accusantium quaerat omnis, corporis ratione in.</p>
                            <div className="mt-8">
                                <button><span className="pb-1 font-semibold text-white border-b-4 border-white hover:text-green hover:border-green">read more</span> </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className=" bg-navy">
                    <div className="px-6 py-10 bg-cover" >
                        <div className="">
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Value Proposition Design</span>
                            </Link>
                        </div>
                        <div className="mt-8">
                            <h3 className="mb-4 text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, tempora!</h3>
                            <p className="mb-8 text-lg text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quis sint explicabo voluptate enim dolorum maiores autem numquam sequi accusantium quaerat omnis, corporis ratione in.</p>
                            <div >
                                <button><span className="pb-1 font-semibold text-white border-b-4 border-white hover:text-green hover:border-green">read more</span> </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="bg-navy">
                    <div className="px-6 py-10 bg-cover" >
                        <div className="">
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">Experience Design</span>
                            </Link>
                            <Link to="#">
                                <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide text-gray-900 uppercase bg-green-300 hover:bg-white">MVP Development</span>
                            </Link>
                        </div>
                        <div className="mt-8">
                            <h3 className="mb-4 text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, tempora!</h3>
                            <p className="mb-8 text-lg text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quis sint explicabo voluptate enim dolorum maiores autem numquam sequi accusantium quaerat omnis, corporis ratione in.</p>
                            <div >
                                <button><span className="pb-1 font-semibold text-white border-b-4 border-white hover:text-green hover:border-green">read more</span> </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="h-8 pt-4 mx-auto my-auto md:pt-0">
                    <button><span className="pb-1 font-semibold text-right transition duration-200 border-b-4 border-navy hover:text-navy-300 hover:border-navy-300">see all work </span> </button>
                </div>


            </div>

        </div>
    )
}

export default CasePreview
