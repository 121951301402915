import React from 'react'
import { Link } from 'gatsby'

function PerspectivesPreview() {
    return (
        <div className="container py-20 md:py-32 text-navy">


            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                <div className="col-span-full">
                    <div className="font-black tracking-wider uppercase font-display text-light-navy">perspectives</div>
                    <div className="pb-20 text-3xl font-semibold font-display">Our Latest Thoughts and Insights</div>
                </div>


                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div>
                        <div className="bg-cover h-60 bg-navy-400 bg-perspective-1 mix-blend-multiply">

                            <div className="p-4">

                                <Link to="#">
                                    <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide uppercase transition duration-700 bg-white hover:text-white text-navy hover:bg-navy">Leadership</span>
                                    <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide uppercase transition duration-700 bg-white hover:text-white text-navy hover:bg-navy">Human Centered Design</span>
                                </Link>

                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col content-between px-6 py-12">
                        <p className="text-lg font-semibold text-gray-700">Leadership towards Human-Centered Organisations.</p>
                        <div>
                            <p className="mt-4">There has been much said recently about the pitfalls of not following a human-centred approach. <br />
                                We have a chat with some prominent industry leaders to hear how they infuse human-centeredness into business as usual.
                                What are some of the key challenges to overcome and structures needed to win ?</p>
                        </div>
                        <div className="mt-8">
                            <button><span className="pb-1 font-semibold border-b-4 text-navy border-navy hover:text-navy-300 hover:border-navy-300">read more</span> </button>
                        </div>
                    </div>

                </div>

                <div className="transition duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div>
                        <div className="bg-cover h-60 bg-navy-400 bg-perspective-2 mix-blend-multiply">

                            <div className="p-4">

                                <Link to="#">
                                    <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide uppercase transition duration-700 bg-white hover:text-white text-navy hover:bg-navy">Customer Discovery</span>
                                </Link>
                                <Link to="#">
                                    <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide uppercase transition duration-700 bg-white hover:text-white text-navy hover:bg-navy">Innovation</span>
                                </Link>

                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col content-between px-6 py-12">
                        <p className="text-lg font-semibold text-gray-700">Why you shouldn't always listen to your customers.</p>
                        <div>
                            <p className="mt-4">As Henry Ford famously said; "If I had asked people what they wanted, they would have said faster horses". Indeed, there are times when what the customer wants is in direct conflict with the strategic vision and innovation aspirations of the organization.
                                Here are the top 5 scenarios where you should not listen to the customer.</p>
                        </div>
                        <div className="mt-8">
                            <button><span className="pb-1 font-semibold border-b-4 text-navy border-navy hover:text-navy-300 hover:border-navy-300">read more</span> </button>
                        </div>
                    </div>

                </div>



                <div className="duration-700 rounded shadow-lg text-gray bg-white-50 hover:shadow-2xl">
                    <div>
                        <div className="bg-cover h-60 bg-navy-400 bg-perspective-3 mix-blend-multiply">

                            <div className="p-4">

                                <Link to="#">
                                    <span className="px-2 py-1 mr-2 text-xs font-semibold tracking-wide uppercase transition duration-700 bg-white hover:text-white text-navy hover:bg-navy">Venture Design</span>
                                </Link>

                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col content-between px-6 py-12">

                        <div>
                            <p className="text-lg font-semibold text-gray-700">Understanding Design as Business Intelligence and Risk Mitigation</p>
                            <p className="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ullam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis cupiditate consequatur blanditiis iste ab nihil ex officia, excepturi minima labore.</p>
                        </div>
                        <div className="mt-8">
                            <button><span className="pb-1 font-semibold border-b-4 text-navy border-navy hover:text-navy-300 hover:border-navy-300">read more</span> </button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default PerspectivesPreview
