import React from 'react'
import Nav from './Nav'
import '../styles/tailwind.css'
import Footer from './Footer'
import '@fontsource/montserrat'
import '@fontsource/poppins'

function Layout(props) {
    return (
        <div className="antialiased font-display bg-lightest-navy">
            <Nav />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout
