import React from 'react'
import Typewriter from 'typewriter-effect'
import { Link } from 'gatsby'

function Billboard() {
    return (
        <div className="container flex flex-col justify-center py-20 md:py-32">
            <div><div className="max-w-2xl md:w-3/5">
                <div className="text-sm font-extrabold tracking-wider text-green-500 uppercase font-display md:text-base">we are a pan-african design & innovation studio</div>
                <div className="pt-4 text-4xl font-semibold leading-snug tracking-wide text-white md:leading-tight font-display h1 md:text-6xl">Building digital products & ventures for the future of <span className="text-green">
                    <Typewriter
                        options={{ loop: true }}
                        onInit={(typewriter) => {


                            typewriter

                                .typeString("fintech")

                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("government")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("retail")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("healthcare")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("civictech")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("startups")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Africa")
                                .pauseFor(10000)
                                .deleteAll()
                                .start();
                        }}
                    /></span></div>
                <div className="pt-8 pb-12 text-xl md:text-2xl text-lightest-slate-500">Human-centred Design solutions to launch and scale leading edge ventures and experience innovation for impact.</div>
                <Link to="/about"><button className="px-4 py-2 font-semibold uppercase border-2 rounded text-green border-green hover:bg-green hover:text-navy">more about us</button></Link>
            </div>
                <div className="hidden md:w-2/5 md:block"></div>
            </div>

        </div>
    )
}

export default Billboard
